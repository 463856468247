import axios from 'axios';
import moment from 'moment';

import {Constants} from "resources/constants"


export const Axios = axios.create({
  baseURL: Constants.baseServiceUrl,
  timeout: 900000
});

Axios.defaults.headers.post['Content-Type'] = 'application/json';

const onRequest = (config) => {
  return config;
};

const onRequestError = (error) => {
  return Promise.reject(error);
};

const onResponse = (response) => {
  return response;
};

const onResponseError = (error) => {

  if (error.response.status === 429) {
    localStorage.setItem('rate_limited', true);
    localStorage.setItem("rate_limited_until", moment().add(15, 'minutes') )

  }
  return Promise.reject(error);
};

// REQUEST INTERCEPTOR
Axios.interceptors.request.use(onRequest, onRequestError);

// RESPONSE INTERCEPTOR
Axios.interceptors.response.use(onResponse, onResponseError);

export function setDefaultHeaders(headers) {
  Axios.defaults.headers = {...Axios.defaults.headers, ...headers};
}

export function setToken(accessToken) {
  if (accessToken) {
    Axios.defaults.headers.common.authorization = `Bearer ${accessToken}`;
  } else {
    delete Axios.defaults.headers.common.authorization;
  }
}
