import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function Alert(props) {
  const {open, setOpen, title = "Warning", body = "Are you sure?", onOkay = () => {} } = props
  const handleCancel = () => {
    setOpen(false);
  }

  const handleOkay = () => {
    onOkay()
    setOpen(false);

  }

  return (
    <Dialog
      open={open}
      onClose={handleCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>

      <DialogContent style={{ minWidth: 450 }}>
        <DialogContentText id="alert-dialog-description">
          {body}
        </DialogContentText>
      </DialogContent>

      <DialogActions>
      <Button onClick={handleCancel} color="error">
        Cancel
      </Button>

      <Button onClick={handleOkay} color="primary" autoFocus>
        Ok
      </Button>
      </DialogActions>
    </Dialog>
  );
}
