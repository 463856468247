import { createContext, useEffect, useReducer } from "react";
import axios from "axios";
// CUSTOM COMPONENT
import { MatxLoading } from "app/components";
import {login as loginService} from "services/auth"
import {setToken} from "utils/Axios"
import moment from "moment";
import { useNavigate } from "react-router-dom";

const initialState = {
  user: null,
  accessToken: null,
  isInitialized: false,
  isAuthenticated: false,
  rateLimited: false,
  rateLimitedUntil: undefined
};

const reducer = (state, action) => {
  switch (action.type) {
    case "INIT": {
      const { accessToken, isAuthenticated, rateLimited, rateLimitedUntil } = action.payload;
      setToken(accessToken)
      return { ...state,isInitialized: true, accessToken, isAuthenticated, rateLimited, rateLimitedUntil};
    }

    case "LOGIN": {
      return { ...state, isAuthenticated: true, user: action.payload.user, accessToken: action.payload.accessToken };
    }

    case "LOGOUT": {
      setToken(undefined)
      return { ...state, isAuthenticated: false, user: null, acaccessToken: null };
    }

    case "REGISTER": {
      const { user } = action.payload;

      return { ...state, isAuthenticated: true, user };
    }


    case "RATE": {
      const { rateLimited, rateLimitedUntil } = action.payload;

      return { ...state, rateLimited, rateLimitedUntil };
    }

    default:
      return state;
  }
};

const AuthContext = createContext({
  ...initialState,
  method: "JWT",
  login: () => {},
  logout: () => {},
  register: () => {}
});

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);



  const  navigate = useNavigate()

  const login = async (email, password) => {
    const {data: response} = await loginService(email, password);
    const {access_token, refresh_token} = response.data
    setToken(access_token)
    localStorage.setItem('access_token', access_token);
    dispatch({ type: "LOGIN", payload: { user: null, accessToken: access_token } });
  };

  const register = async (email, username, password) => {
    const {data: response} = await axios.post("/api/auth/register", { email, username, password })


    dispatch({ type: "REGISTER", payload: { user: null, accessToken: null } });
  };

  const logout = () => {

    localStorage.removeItem('access_token');
    dispatch({ type: "LOGOUT", payload: { user: null, accessToken: null }  });
    navigate("/session/signin")
  };

  useEffect(() => {
    (async () => {
      try {
        const persisted_access_token = await localStorage.getItem('access_token');
        const rate_limited = await localStorage.getItem("rate_limited")
        const rate_limited_until = await localStorage.getItem("rate_limited_until")


        dispatch({ type: "INIT", payload: { 
          isAuthenticated: persisted_access_token === "null" ? false : true, 
          accessToken: persisted_access_token === "null" ? null: persisted_access_token,
          rateLimited: rate_limited === "true" ? true : false,
          rateLimitedUntil: rate_limited_until
        }});
      } catch (err) {
        console.error(err);
        dispatch({ type: "INIT", payload: { isAuthenticated: false, user: null } });
      }
    })();
  }, []);

  // useEffect(() => {
  //       (async () => { 
  //         const rate_limited = await localStorage.getItem("rate_limited")
  //         const rate_limited_until = await localStorage.getItem("rate_limited_until")


  //       dispatch({ type: "RATE", payload: { 
  //         rateLimited: rate_limited === "true" ? true : false,
  //         rateLimitedUntil: rate_limited_until
  //       }});
  //       }
  //     )()
  // })

  // SHOW LOADER
  if (!state.isInitialized) return <MatxLoading />;


  if (state.rateLimited && moment().isBefore(moment(state.rateLimitedUntil))) return <div>Rate Limited!</div>

  if (state.rateLimited && moment().isAfter(moment(state.rateLimitedUntil))) {
    localStorage.setItem('rate_limited', false);
    localStorage.setItem("rate_limited_until", null )
  }

  return (
    <AuthContext.Provider value={{ ...state, method: "JWT", login, logout, register }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
