export const Constants = {
    // baseUrl: "http://localhost:4000/api",
    baseUrl: "https://wpbot-api.kreo.solutions/api",
    authBase: "auth",
    templatesBase: "templates",
    customerBase: "customer",
    messageLogsBase: "message-logs",
    brandBase: "brand",
    campaignBase: "campaign",
    messagesBase: "messages",
}
